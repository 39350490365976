import * as React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
// import slugify from "react-slugify";
import "../../styles/global.css";
import { Navbar } from "../../components";

const Layout = ({ children }) => {
    const data = useStaticQuery(graphql`
        query LayoutQuery {
            site {
                siteMetadata {
                    title
                    description
                    menuEntries {
                        name
                        link
                    }
                }
            }
        }
    `);

    // let i = 0;

    return (
        <>
            <Helmet>
                <html lang="pl" />
                <meta charSet="utf-8" />
                <title>{data.site.siteMetadata.title}</title>
                <meta
                    name="description"
                    content={data.site.siteMetadata.description}
                />
            </Helmet>
            <Navbar siteTitle={data.site.siteMetadata?.title || `Title`} />
            <div className="container mx-auto px-4">
                <main className="bg-white">{children}</main>
            </div>
            <footer>
                {/* <div className="bg-gray-100">
                    <div className="max-w-6xl m-auto text-gray-800 flex flex-wrap justify-center">
                        <div className="p-5 w-48 ">
                            <div className="text-xs uppercase text-gray-500 font-medium">
                                Linki
                            </div>
                            <Link className="my-3 block" to="/aktualnosci">
                                Aktualności{" "}
                            </Link>
                            <Link className="my-3 block" to="/rekrutacja">
                                Rekrutacja{" "}
                            </Link>
                            {data.directus.genericpage.map(entry => {
                                return (
                                    <Link
                                        key={i++}
                                        to={"/" + slugify(entry.title)}
                                        partiallyActive={true}
                                        className="my-3 block"
                                    >
                                        {entry.title}
                                    </Link>
                                );
                            })}
                        </div>
                        <div className="p-5 w-48 ">
                            <div className="text-xs uppercase text-gray-500 font-medium">
                                Adres
                            </div>
                            <a
                                className="my-3 block"
                                href="https://www.zsi.kielce.pl/"
                            >
                                Zespół Szkół Informatycznych im. gen. Józefa
                                Hauke Bosaka{" "}
                            </a>
                            <a
                                className="my-3 block"
                                href="https://g.page/Informatyk_Kielce?share"
                            >
                                ul. Warszawska 96
                                <br />
                                25 - 401 Kielce{" "}
                            </a>
                        </div>
                        <div className="p-5 w-48">
                            <div className="text-xs uppercase text-gray-500 font-medium">
                                Kontakt
                            </div>
                            <a className="my-3 block" href="tel:413676790">
                                tel: 41 367 67 90{" "}
                            </a>
                            <div className="my-3">fax: 41 367 69 33 </div>
                            <a
                                className="my-3 block"
                                href="mailto:szkola@zsi.kielce.pl"
                            >
                                szkola@zsi.kielce.pl{" "}
                            </a>
                        </div>
                    </div>
                </div> */}
                <div className="bg-gray-100 pt-2 ">
                    <div
                        className="flex pb-5 px-3 m-auto pt-5 text-gray-800 text-sm flex-col
                md:flex-row max-w-6xl"
                    >
                        <div className="mt-2">
                            <p>Projekt współfinansowany  ze środków Unii Europejskiej w ramach Europejskiego Funduszu Społecznego.<br />
                            Dofinansowanie projektu z UE: 896 570,50 PLN</p>
                            <p className="mt-2">© Copyright {new Date().getFullYear()}. Zespół Szkół Informatycznych w Kielcach - Technikum nr 7</p>
                        </div>
                        <div className="md:flex-auto md:flex-row-reverse mt-2 flex-row flex">
                            <p className="right">
                                Code &amp; design by{" "}
                                <a
                                    className="underline"
                                    href="https://github.com/DLowHP"
                                >
                                    Daniel Fidor
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
};

Layout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Layout;
