import * as React from "react";
import { string } from "prop-types";
import { useStaticQuery, graphql, Link } from "gatsby";
import slugify from "react-slugify";
import "../../styles/global.css";

const Navbar = ({ siteTitle, ...props }) => {
    const [open, setOpen] = React.useState(false);
    const isHidden = open ? false : true;
    let i = 0;

    const data = useStaticQuery(graphql`
        query NavbarQuery {
            directus {
                genericpage {
                    title
                }
            }
        }
    `);

    return (
        <nav className="fixed w-screen top-0 flex items-center justify-between flex-wrap bg-green-50 p-6 z-50 shadow-xl">
            <div className="flex items-center flex-no-shrink text-black mr-6">
                <Link
                    to="/"
                    className="font-bold text-xl lg:text-3xl tracking-tight"
                >
                    {siteTitle}
                </Link>
            </div>
            <div className="block lg:hidden">
                <button
                    className="flex flex-col w-10 h-10 items-center justify-around px-3 py-2 border rounded border-blue-200 hover:text-white hover:border-white"
                    onClick={() => setOpen(!open)}
                >
                    <span
                        className={`block relative w-5 h-0.5 bg-black transition-all duration-300 ease-linear origin-left transform ${
                            open ? "rotate-45 translate-x-0.5" : "rotate-0"
                        }`}
                    ></span>
                    <span
                        className={`block relative w-5 h-0.5 bg-black transition-all duration-300 ease-linear origin-left transform ${
                            open
                                ? "translate-x-5 opacity-0"
                                : "translate-x-0 opacity-1"
                        }`}
                    ></span>
                    <span
                        className={`block relative w-5 h-0.5 bg-black transition-all duration-300 ease-linear origin-left transform ${
                            open ? "-rotate-45 translate-x-0.5" : "rotate-0"
                        }`}
                    ></span>
                </button>
            </div>
            <div className="w-full block flex-grow  lg:flex lg:items-end lg:w-auto">
                <div
                    aria-hidden={isHidden}
                    className={`text-sm flex-col lg:flex-row lg:justify-end lg:text-lg lg:flex-grow last:mr-0 ${
                        open ? "" : "hidden"
                    } lg:flex`}
                >
                    <Link
                        key={i++}
                        to="/aktualnosci"
                        partiallyActive={true}
                        className="block mt-4 lg:inline-block lg:mt-0 hover:text-blue-400 mr-4"
                    >
                        Aktualności
                    </Link>
                    <Link
                        key={i++}
                        to="/galeria"
                        partiallyActive={true}
                        className="block mt-4 lg:inline-block lg:mt-0 hover:text-blue-400 mr-4"
                    >
                        Galeria
                    </Link>
                    {data.directus.genericpage.map(entry => {
                        return (
                            <Link
                                key={i++}
                                to={"/" + slugify(entry.title)}
                                partiallyActive={true}
                                className="block mt-4 lg:inline-block lg:mt-0 hover:text-blue-400 mr-4"
                            >
                                {entry.title}
                            </Link>
                        );
                    })}
                </div>
            </div>
        </nav>
    );
};

Navbar.propTypes = {
    siteTitle: string.isRequired,
};

export default Navbar;
