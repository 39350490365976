import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import "../../styles/global.css";

const Hero = () => {
    return (
        <div className="flex flex-col justify-end items-center w-full h-screen">
            <StaticImage
                className="hero"
                src="../../images/baner.jpg"
                alt="Hero background"
                layout="fullWidth"
                placeholder="blurred"
            />
            <svg
                className="animate-bounce w-10 h-10 mb-5"
                focusable="false"
                width="1.25rem"
                height="1.25rem"
                preserveAspectRatio="xMidYMid meet"
                viewBox="0 0 20 20"
            >
                <g fill="none">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M16.707 10.293a1 1 0 0 1 0 1.414l-6 6a1 1 0 0 1-1.414 0l-6-6a1 1 0 1 1 1.414-1.414L9 14.586V3a1 1 0 0 1 2 0v11.586l4.293-4.293a1 1 0 0 1 1.414 0z"
                        fill="#ffffff"
                    />
                </g>
            </svg>
        </div>
    );
};

export default Hero;
