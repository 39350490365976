import React from "react";
import { object } from "prop-types";

const FeedPage = ({ data }) => {
    const creationDate = new Date(data.date_created);

    const pad = number => (number < 10 ? "0" + number : number);

    const creationDateString =
        creationDate.getFullYear() +
        "-" +
        pad(creationDate.getMonth() + 1) +
        "-" +
        pad(creationDate.getDate());

    return (
        <>
            <div className="flex flex-col shadow-xl rounded-2xl mb-6">
                <div className="border-gray-400 border-b-2 py-3 px-6 pb-2">
                    {data.title !== undefined ? (
                        <span className="text-3xl font-bold block">
                            {data.title}
                        </span>
                    ) : (
                        ""
                    )}
                    <span className="text-gray-400">
                        {data.author !== undefined
                            ? data.author
                            : `${data.user_created.first_name} ${data.user_created.last_name}`}
                        {", "}
                    </span>
                    <time
                        className="text-gray-400"
                        dateTime={data.date_created}
                    >
                        {creationDateString}
                    </time>
                </div>
                <div className="px-6">
                    <div
                        dangerouslySetInnerHTML={{
                            __html: data.content,
                        }}
                    />
                </div>
            </div>
        </>
    );
};

FeedPage.propTypes = {
    data: object,
};

export default FeedPage;
